.hero {
  background: url('../../assets/images/homehero.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: left;
   height: 90vh;
   width: 100%;
   background-position: center center;
   align-items: center;

   img {display: none;}
 
 
   .content {
       display: flex;
       flex-direction:column;
       text-align: center;
       justify-content: center;
       height: 90vh;
       width: 85%;
       margin: auto;
       
     h1 {
       font-size: 6.5vw;
       line-height: 0.9;
       margin-bottom: 0px;
       color: white;
       padding-top: 4vh;
       padding-bottom: 1.4vh;
       margin-right: 20vh;
       margin-left: 20vh;
       background-color: #00000044;
     }
     p {
       font-size: 1.2vw;
       color: white;
       padding-bottom: 4vh;
       margin-right: 20vh;
       margin-left: 20vh;
       background-color: #00000044;
       border: 2vh solid transparent;
     }
   }

 }

 .intro{
   margin-top: 7vh;
   text-align: center;

   p{
    font-size: larger;
    font-weight: 400;
  }

  h3{
    font-size: 6vh;
    .fa-gears{
      color: #f03838;
    } 
    .fa-lightbulb{
      color: #ebf191;
    } 
  }
 }

 .benefits{
   h1{
    margin-top: 7vh;
    font-size: xx-large;
    @media only screen and (max-width: 998px) {
      text-align: center;
    }
  }

  p{
   font-size: large;
   font-weight: 300;
 }
}

.reasons{
  h3 {
   margin-top: 3vh;
 }

 h4{
  margin-top: 5vh;
  color: #38aff0;
}

 h2{
   text-align: center;
 }

 p{
  font-size: large;
  font-weight: 300;
}


}

.offering h5, .offering ul{
  text-align: left;
  
}

.offering{
  text-align: center;
  background-color: #9aa7af24;
  margin-top: 7vh;

  p{
     font-size: larger;
     font-weight: 300;
     margin-top: 2vh;
   }

   ul{
     padding-bottom: 10vh;
     @media only screen and (max-width: 998px) {
      padding-bottom: 7vh;
      }
   }

   iframe{
    margin-top: 4vh;
    width: 60vw;
    height: 60vh;
    @media only screen and (max-width: 768px) {
      height: 30vh;
      width: 80vw;
      }
  }

   img{
    margin-top: 16vh;
    width: 550px;
    height: 300px;
    @media only screen and (max-width: 1200px) {
    width: 450px;
    height: 270px;
    margin-top: 20vh;
    }

    @media only screen and (max-width: 998px) {
      width: 400px;
      height: 230px;
      margin-top: 4vh;
      margin-left: 22%;
      }

      @media only screen and (max-width: 767px) {
        width: 300px;
        height: 170px;
        margin-top: 4vh;
        margin-left: 21%;
        }

      @media only screen and (max-width: 768px) and (min-width: 768px){
        width: 300px;
        height: 170px;
        margin-top: 4vh;
        margin-left: 28.5%;        
        }

      @media only screen and (max-width: 500px) {
        width: 220px;
        height: 120px;
        margin-top: 4vh;
        margin-left: 27%;
        }

      @media only screen and (max-width: 450px) {
        margin-left: 23%;
        }

      @media only screen and (max-width: 400px) {
        margin-left: 20%;
        }
   }

 
   h1{
     font-size: 4vh;
     padding-top: 7vh;
     @media only screen and (max-width: 998px) {
      padding-top: 7vh;
      text-align: center;
    }
   }

   h5{
     padding-top: 7vh;
     @media only screen and (max-width: 768px) {
      text-align: center;
    }
    @media only screen and (max-width: 998px) {
      padding-top: 4vh;
    }
   }

   ul{
        padding-left: 0px;
        @media only screen and (max-width: 768px) {
          text-align: left;
        }
    }

    li{
        list-style-type: none;
        
    }

    i{
        margin-right: 10px;
        color: #38aff0;
        
    }
  }


.trial{
  background-color: rgb(38, 42, 57);
  margin-top: 7vh;
  text-align: center;
  color: white;

  iframe{
    margin-top: 10vh;
    width: 60vw;
    height: 60vh;
    @media only screen and (max-width: 768px) {
      height: 30vh;
      width: 80vw;
      }
  }

  button{
    margin-bottom: 10vh;
  }

  .offers{
  h3, h2, p{
    font-size: x-large;
  }
}

  .offers p{
      font-size: large;
    }


  h3{
    padding-top: 8vh;
    font-weight: 700;
    font-size: xx-large;
    color: #faffb1;
  }

  h2{
    font-weight: 700;
    font-size: xx-large;
    color: #bd6e88d4;
  }

  p{
    font-size: xx-large;
  }

  .offers{
    margin-top: 7vh;
    p{
      font-size: large;
    }
    

  }

  button{
    margin-top: 6vh;
  }

}

.faq-intro{
  margin-top: 7vh;
  margin-bottom: 3vh;
  font-size: 1.9rem;
  text-align: center;
  max-width: 90%;
}

.accordion-item{
  margin-bottom: 20px;
}


.accordion-button:not(.collapsed) {
  color: white; 
  background-color: #38aff0; 
}

.accordion-button:hover {
  color: white; 
  background-color: #38aff0; 
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: transparent; 
  box-shadow: none;
}


 @media only screen and (max-width: 1094px) {

  .hero{
    height: 70vh;
  }

  .hero .content{
    height: 35vh;
  }
   
  .hero .content h1 {
      font-size: 5.5vw;
  }
  
  .hero .content p {
    font-size: 1.2vw;
    border: 0.7vh solid transparent;
  }

  .intro h1, .intro h2 {
    font-size: x-large; 
  }

  .intro p{
    margin-bottom: 7vh;
    font-size: large;
  }

  .benefits{
    br{
    display: none;
    }
    
   h1{
    margin-top: 0vh;
    }
  }

  .benefits h1, .intro h2, .reasons h3 {
    font-size: large;
    
  }

  .reasons p, .reasons h3{
    text-align: left;
    font-size: medium;
  }

  .reasons{
    br{
    display: none;
    }
    
   h3{
    margin-top: 0vh;
    }

    h4{
      margin-top: 1vh;
    }
  }

  .trial {     
    h3, h2, p{
      font-size: large;
    }

    .offers p{
        font-size: medium;
      } 
  }

  .offering{
   
      p{
       font-size: large;
     }
   
     h1{
       font-size: x-large;
     }
  
     h5{
       font-size: large;
      }
     }
  
     ul{
        font-size: small;
      }
  
    }
  
  .faq-intro{
    font-size: x-large;
  
  }
  

@media only screen and (max-width: 900px) {

  .hero, .content{
      height: 60vh;

      .btn-lg, .btn-group-lg > .btn {
        font-size: 0.5rem;
    }

  }

  .hero .content h1 {
    padding-top: 3vh;
    font-size: 6vw;
    margin-right: 5vh;
    margin-left: 5vh;
}
  
  .hero .content p {
    padding-bottom: 3vh;
    font-size: small;
    margin-right: 5vh;
    margin-left: 5vh;

  }

  .btn-lg, .btn-group-lg > .btn {
    font-size: 0.5rem;
}

  .intro h1, .intro h2 {
    font-size: large; 
  }

  .intro p{
    font-size: medium;
  }

  .benefits h1, .intro h2, .reasons h3 {
    font-size: large;
    
  }

  .reasons p, .reasons h3{
    text-align: left;
    font-size: medium;
  }

  .reasons {
    margin-top: 4vh;
  }

  .offering h1 {
    text-align: center;
  }

  .trial {     

    h3, h2, p{
      font-size: medium;
    }

    .offers p{
        font-size: small;
      } 

    button{
        margin-top: 3vh;
      }
  }

  .offering{
   
      p{
       font-size: medium;
     }
   
     h1{
       font-size: 3vh;
     }
  
     h5{
       font-size: medium;
      }
     }
  
     ul{
        font-size: small;
      }
  
    }
  
  .accordion-header{
    h3{
        font-size: large;
    }
}
.accordion-body{
  font-size: medium;
}



@media only screen and (max-width: 600px) {
  .hero .content h1 {
    padding-top: 2vh;
    padding-bottom: 0.5vh;
    font-size: 7vw;
    margin-right: 1vh;
    margin-left: 1vh;
    @media only screen and (max-width: 380px) {
      font-size: 8.5vw;
      border: 0.4vh solid transparent;
  
      }
  }
  
  .hero .content p {
    padding-bottom: 1.5vh;
    font-size: 2.2vw;
    margin-right: 1vh;
    margin-left: 1vh;
    border: 0.7vh solid transparent;
    @media only screen and (max-width: 380px) {
    font-size: 2.5vw;
    border: 0.3vh solid transparent;
    border-top: 0vh;

    }

  }

  .intro, .intro h1, .intro h2 {
    font-size: medium;
    
  }


  .intro {
    margin-top: 5vh;
    h3{
      font-size: 5vh;
    }

    br{
      display: none;
    }
    p{
      font-size: small;
    }
  }

  .benefits{
    h1{
      font-size: small;
    }

    br{
      display: none;
    }

    p{
      font-size: small;
    }

  }

  .reasons{
    h4{
      font-size: medium;
    }
    h3{
      font-size: small;
    }

    br{
      display: none;
    }
    p{
      font-size: x-small;
    }
  }

  .offering{

    p{
     font-size: x-small;
   }
 
   h1{
     font-size: 2vh;
   }

   h5{
     font-size:  small;
    }
   }

   ul{
      font-size: x-small;
    }

    .trial {     
      h3, h2, p{
        font-size: small;
      }
  
      h1{
        font-size: small;
      }
  
      .offers p{
          font-size: x-small;
        } 
  
    }

    .faq-intro, .accordion-header h3{
      font-size: small;
    }
  
    .accordion-body {
      font-size: small;
    }

  }




