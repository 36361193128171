.jumbotron{
    height: 53.5vh;
    background-color:white !important;

    h1{
        font-size: 5.5vw;
    }
    
}

@media only screen and (max-width: 998px) {
    .jumbotron{
        h1{
            font-size: 4.5vw;
        }

        p{
            font-size: 1.5vw;
        }
  }
}

@media only screen and (max-width: 768px) {
    .jumbotron{
        h1{
            font-size: 5.5vw;
        }

        p{
            font-size: 3.5vw;
        }
  }
}