.contacthero {
    background: url('../../assets/images/contacthero.jpg');
     background-size: cover;
     background-repeat: no-repeat;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: left;
     height: 50vh;
     width: 100%;
     background-position: center center;
     align-items: center;
   
   
     .contactcontent {
         display: flex;
         flex-direction:column;
         text-align: center;
         justify-content: center;
         height: 50vh;
         width: 25%;
         margin: auto;

       h1 {
         font-size: 2.5vw;
         line-height: 0.9;
         margin-bottom: 0px;
         color: white;
         background-color: #00000044;
         padding-top: 10%;
         padding-bottom: 10%;
         border: 1px #00000044;

       }
     }
  
   }

#contactform{
    margin-top: 5vh;

    h2 {
        font-weight: 300;
    }

    p{
        font-weight: 300;
    }

    button{
        margin-top: 4vh;
        margin-bottom: 4vh;
     }

     .col-xs-12, .form-control{
      margin-top: 2vh;
    }

}


@media only screen and (max-width: 1300px) {

    .contacthero{
      height: 35vh;
    }
  
    .contacthero .contactcontent{
      height: 17.5vh;
    }
    

}

@media only screen and (max-width: 900px) {
    .contacthero .contactcontent{
      width: 40%;
        h1{
            font-size: 5vw;
        }
      }

}

@media only screen and (max-width: 600px) {
    .contacthero .contactcontent{
        h1{
            font-size: 5.5vw;
        }
      }

      .col-sm-6{
        margin-top: 2vh;
      }
      
      p{
        text-align: center;
      }
      

    #contactform{

         h2 {
            text-align: center;
            font-size: larger;
        }
        
    }

}

@media only screen and (max-width: 400px) {

.contacthero .contactcontent {
  width: 65%;
 
 h1 {
   font-size: 7vw;
 }
 }

}