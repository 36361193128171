.privacypage{
    margin-top: 3vh;
    
    h1{
        font-size: x-large;
    }

    h2, h3{
        font-size: large;
    }

    @media only screen and (max-width: 900px) {

        h1, h2, h3, p, ul{
            font-size: medium;
        }
    }

    @media only screen and (max-width: 600px) {

        h1, h2, h3, p, ul{
            font-size: small;
            text-align: left;
        }
    }

    @media only screen and (max-width: 400px) {

        h1, h2, h3, p, ul{
            font-size: x-small;
        }
    }
}